import { API } from "@/main";

const state = {
  loadingTickets: "",
  loadingTicket: "",
  loadingUserInfo: "",
  ticket: null,
  tickets: [],
  logs: [],
  comments: [],
  userInfo: null,
  lastPage: 1,
  total: 0
};

const getters = {};

const actions = {
  loadTicket({ commit }, data) {
    // commit("SET_LOADING", { type: "ticket", value: "LOADING" });
    return new Promise((resolve, reject) => {
      API.get(
        data.user
          ? `api/clients/tickets/${data.id}`
          : `api/support/tickets/${data.id}`
      )
        .then(response => {
          commit("SET_TICKET", response.data.data);
          commit("SET_LOADING", { type: "ticket", value: "SUCCESS" });
          resolve(response);
        })
        .catch(error => {
          commit("SET_LOADING", { type: "ticket", value: "ERROR" });
          reject(error);
        });
    });
  },
  loadTickets({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(data.user ? "api/clients/tickets" : "api/support/tickets", {
        params: {
          search: data.searchType === "ticket" ? data.search : "",
          message: data.searchType === "message" ? data.search : "",
          status: data.status,
          with_answer: data.with_answer,
          support: data.support,
          theme: data.theme,
          order: data.order,
          source: data.source,
          page: data.page,
          date: data.date
        }
      })
        .then(response => {
          if (data.page === 1) {
            commit("SET_TICKETS", response.data.data);
          } else {
            commit("ADD_TICKETS", response.data.data);
          }
          commit("SET_LAST_PAGE", response.data.meta.last_page);
          commit("SET_TOTAL", response.data.meta.total);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createTicket(_, data) {
    return new Promise((resolve, reject) => {
      API.post("api/clients/tickets", {
        source_id: +localStorage.getItem("source"),
        theme_id: data.themeID
        // text: data.text
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateTicket(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`api/support/tickets/${data.id}`, data)
        .then(response => {
          // commit("SET_TICKET", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  loadTicketLogs({ commit }, id) {
    return new Promise((resolve, reject) => {
      API.get(`api/support/tickets/${id}/logs`)
        .then(response => {
          commit("SET_TICKET_LOGS", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  loadTicketComments({ commit }, id) {
    return new Promise((resolve, reject) => {
      API.get(`api/support/tickets/${id}/comments`)
        .then(response => {
          commit("SET_TICKET_COMMENTS", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  loadUserInfo({ commit }, id) {
    commit("SET_LOADING", { type: "userInfo", value: "LOADING" });
    return new Promise((resolve, reject) => {
      API.get(`api/support/clients/${id}`)
        .then(response => {
          commit("SET_USER_INFO", response.data);
          commit("SET_LOADING", { type: "userInfo", value: "SUCCESS" });
          resolve(response);
        })
        .catch(error => {
          commit("SET_LOADING", { type: "userInfo", value: "ERROR" });
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_TICKET(state, payload) {
    state.ticket = payload;
  },
  SET_TICKETS(state, payload) {
    state.tickets = payload;
  },
  SET_TICKET_LOGS(state, payload) {
    state.logs = payload;
  },
  SET_TICKET_COMMENTS(state, payload) {
    state.comments = payload;
  },
  SET_LOADING(state, payload) {
    switch (payload.type) {
      case "ticket":
        state.loadingTicket = payload.value;
        break;
      case "tickets":
        state.loadingTickets = payload.value;
        break;
      case "userInfo":
        state.loadingUserInfo = payload.value;
        break;
    }
  },
  ADD_TICKET(state, payload) {
    state.tickets.unshift(payload);
  },
  ADD_TICKETS(state, payload) {
    state.tickets = state.tickets.concat(payload);
  },
  SET_USER_INFO(state, payload) {
    state.userInfo = payload;
  },
  SET_LAST_PAGE(state, payload) {
    state.lastPage = payload;
  },
  SET_TOTAL(state, payload) {
    state.total = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
