import { API } from "@/main";

const state = {
  messages: [],
  presets: []
};

const getters = {
  getPresetById: state => id => {
    return state.presets.find(preset => preset.id === id);
  }
};

const actions = {
  sendMessage(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        data.user
          ? `api/clients/tickets/${data.id}/messages`
          : `api/support/tickets/${data.id}/messages`,
        {
          text: data.content,
          attachments: data.attachments
        }
      )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  loadMessages({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(
        data.user
          ? `api/clients/tickets/${data.id}/messages`
          : `api/support/tickets/${data.id}/messages`
      )
        .then(response => {
          commit("SET_MESSAGES", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  editMessage(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`api/support/tickets/${data.id}/messages/${data.messageID}`, {
        text: data.content
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  sendCommentMessage(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/support/tickets/${data.id}/comments`, {
        text: data.content
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  sendFiles(_, data) {
    return new Promise((resolve, reject) => {
      API.post("api/attachments", data, {
        "Content-Type": "multipart/form-data"
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  loadFile(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/attachments/${data.id}`, {
        responseType: "blob"
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", data.name); //or any other extension
          document.body.appendChild(link);
          link.click();
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  loadPresets({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/support/answers")
        .then(response => {
          commit("SET_PRESETS", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createPreset(_, data) {
    return new Promise((resolve, reject) => {
      API.post("api/support/answers", {
        title: data.name,
        text: data.content
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  editPreset(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`api/support/answers/${data.id}`, {
        title: data.name,
        text: data.content
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deletePreset(_, id) {
    return new Promise((resolve, reject) => {
      API.delete(`api/support/answers/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_MESSAGES(state, payload) {
    state.messages = payload;
  },
  ADD_MESSAGE(state, payload) {
    state.messages.push(payload);
  },
  SET_PRESETS(state, payload) {
    state.presets = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
