<template>
  <div
    class="ticket-card"
    :class="[
      ticket.status === 'done' ? 'ticket-card--close' : '',
      ticket.id === parseInt($route.params.id) ? 'ticket-card--selected' : '',
      ticket.has_new_messages ? 'ticket-card--new-message' : ''
    ]"
  >
    <div class="ticket-card-label-list">
      <div
        class="ticket-card-label-list__item ticket-card-label-list__item--first"
      >
        {{ ticket.source }}
      </div>

      <div
        class="ticket-card-label-list__item ticket-card-label-list__item--second"
      >
        {{ ticket.status }}
      </div>

      <div
        class="ticket-card-label-list__item ticket-card-label-list__item--third"
      >
        Сотрудник:
        {{ ticket.support ? ticket.support.name : "" }}
      </div>
    </div>

    <div class="ticket-card__title ticket-card-title">
      <p v-if="ticket.client" class="ticket-card-title__name">
        {{ ticket.client.first_name }}
      </p>

      <p class="ticket-card-title__type">{{ ticket.theme }}</p>
    </div>

    <div class="ticket-card__text">
      <quill-editor
        :content="cleanMessage(ticket.text)"
        :options="messageOption"
        :disabled="true"
      ></quill-editor>
    </div>

    <div class="ticket-card__date">
      <p>{{ ticket.created_at }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TicketCard",
  props: {
    ticket: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      messageOption: {
        theme: "bubble",
        modules: {
          toolbar: false
        }
      }
    };
  },

  methods: {
    cleanMessage(message) {
      return this.$sanitize(message);
    }
  }
};
</script>

<style lang="scss">
.ticket-card {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  padding: 28px 20px 20px;
  margin-top: 24px;
  transition: transform 0.1s linear;
  cursor: pointer;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.03);

  &--new-message {
    background: #d0deff !important;
  }

  &:hover {
    transform: scale(1.03);
  }
  &:active {
    transform: scale(1);
  }

  &--close {
    opacity: 0.6;
  }

  &--selected {
    left: 22px;
  }

  .ticket-card-label-list {
    display: flex;
    position: absolute;
    top: -10px;
    left: 20px;

    &__item {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      margin-right: 8px;
      padding: 4px 12px;
      border-radius: 4px;
    }

    &__item--first,
    &__item--second,
    &__item--third {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__item--first {
      color: #fba84c;
      background: #fff3de;
      max-width: 80px;
    }
    &__item--second {
      color: #88c268;
      background: #e1f1d8;
      max-width: 100px;
    }
    &__item--third {
      color: #3870ff;
      background: #ebf1ff;
      max-width: 200px;
    }
  }

  &__title {
    display: flex;
    margin-bottom: 10px;
  }

  &-title__name,
  &-title__type,
  &__text p {
    font-size: 14px;
    line-height: 17px;
  }

  &-title__name {
    color: $label-main;
    margin-right: 8px;
    white-space: nowrap;
  }

  &-title__type {
    color: $label-third;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    max-height: 17px;
  }

  &__text {
    .ql-editor {
      padding: 0 !important;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      max-height: 34px;
      color: $label-second;

      * {
        cursor: pointer;
      }
    }
  }

  &__date p {
    font-size: 12px;
    line-height: 14px;
    color: $label-third;
  }
}
</style>
